import React from 'react'

const Bottom = () => {
  return (
    <div className='bottom'>
      <div className="bottom-cont">
        <div>
            <h4>Zildo-find</h4>
            <>This site is owned and operated by lorem ipsum <br />
            lorem ipsum lorem ipsum ipsum</>
            
        </div>

        <div>
            <h4>Contact Us</h4>
            <>+00000000000 <br /></>
            <>support@zildo.com</>
        </div>

        <div>
            <>Age restriction: 18+</>
        </div>
      </div>
    </div>
  )
}

export default Bottom
